import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Image from '../image';

import './avatar.scss';

export function Avatar({ className, children, size, src, asBackground, onClick }) {
	return (
		<div className={classnames('avatar', className, size)} onClick={onClick}>
			{src ? (
				<Image src={src} asBackground={asBackground} className="avatar-image" />
			) : children}
		</div>
	);
}

Avatar.propTypes = {
	size: propTypes.oneOf(['small', 'regular', 'large']),
	src: propTypes.string.isRequired
};

Avatar.defaultProps = {
	size: 'regular'
};

export default Avatar;

import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { createSelector } from 'reselect';
import { selectApp } from '../../store/selectors/app';
import Link from '../lib/link';

import './header.scss';
import Paragraph from '../lib/paragraph';

export const enhance = compose(
	connect(
		createSelector(
			selectApp,
			(app) => ({
				...app,
			})
		)
	),
	mapProps((props) => ({
		...props,
	})),
);

export function Header(props) {
	const { className } = props;

	return (
		<div className={classnames('header', className)}>
			<div className="header-content-container">
				<div className="header-top">
					<div className="left-side">
						<Link to="/" isNavLink>
							Blog
						</Link>
						<Link to="/archive" isNavLink>
							Archive
						</Link>
						<Link to="/tags" isNavLink>
							Tags
						</Link>
					</div>
					<div className="right-side">

					</div>
				</div>
				<div className="header-banner">
					<Link to="/" isNavLink className="banner-link">
						Sean McGary
					</Link>
					<Paragraph size="medium" color="gray">
						Software Engineer, builder of webapps
					</Paragraph>
				</div>
			</div>
		</div>
	);
}

Header.propTypes = {
	hostname: propTypes.string,
	location: propTypes.object,
};

export default enhance(Header);

import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import * as _ from "lodash";
import './materialIcon.scss';

export function MIcon(props) {
	const { icon, outlined, className } = props;
	const iconType = `material-icons${!!outlined ? '-outlined' : ''}`;
	return (
		<i {..._.omit(props, ['outlined', 'badgeContent'])} className={classnames('micon', iconType, className)}>
			{icon}
			{props.badgeContent && (
				<div className="badge">{props.badgeContent}</div>
			)}
		</i>
	);
}

MIcon.propTypes = {
	icon: propTypes.string.isRequired,
	outlined: propTypes.bool
};

export default MIcon;

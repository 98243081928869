import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { compose } from 'recompose';
import { selectApp } from '../../store/selectors/app';

import './appWrapper.scss';

export const enhance = compose(
	connect(selectApp)
);

export function AppWrapper({ route }) {
	return (
		<div className={classnames('app-wrapper')}>
			{renderRoutes(route.routes)}
		</div>
	);
}

export default enhance(AppWrapper);

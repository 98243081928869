import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { createSelector } from 'reselect';
import Headline from '../../components/lib/headline';
import Link from '../../components/lib/link';
import Page from '../../components/page';
import Text from '../../components/lib/text';
import { selectApp } from '../../store/selectors/app';
import { selectArchivePosts } from '../../store/selectors/posts';

import './archivePage.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectApp,
			selectArchivePosts,
			(app, posts) => ({
				...app,
				...posts
			})
		)
	),
	lifecycle({
		componentDidMount() {
			window.scrollTo(0, 0);
		}
	})
);

export function ArchivePage(props) {
	const { posts } = props;
	return (
		<Page
			{...props}
			className={classnames('archive-page')}
			withContent
			pageName="archive-page"
			title="Archive"
		>
			<div className="archive-page-content">
				{_.map(posts, (p) => (
					<div className="archive-post">
						<Link to={`/posts/${p.attributes.slug}`} isNavLink>
							<Headline tag="h3" as="h5">
								{p.attributes.title}
							</Headline>
						</Link>
						<Text size="small" color="gray">
							by {p.attributes.author.name} on {moment(p.attributes.publishedDate).format('LL')}
						</Text>
					</div>
				))}
			</div>
		</Page>
	);
}

export default enhance(ArchivePage);

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose, componentFromProp, defaultProps } from 'recompose';

import './headline.scss';

export function Headline(props) {
	const InnerHeadline = defaultProps({ tag: props.tag })(componentFromProp('tag'));

	const style = `headline-${(props.as || props.tag)}`;
	return (
		<InnerHeadline
			className={classnames(props.className, 'headline', {
				underline: props.underline,
				centered: props.centered,
			}, style, props.weight, props.displayStyle)}
		>
			{props.children}
		</InnerHeadline>
	);
}

Headline.propTypes = {
	as: PropTypes.oneOf(['h0', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	underline: PropTypes.bool,
	centered: PropTypes.bool,
	weight: PropTypes.oneOf(['light', 'medium', 'bold']),
	displayStyle: PropTypes.oneOf(['normal', 'fancy'])
};

Headline.defaultProps = {
	as: 'h1',
	underline: false,
	centered: false,
	weight: 'medium',
	displayStyle: 'normal'
};

export default Headline;

import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps } from 'recompose';
import { createSelector } from 'reselect';
import { buildLoginReturnTo, extractQueryParams } from '../../lib/helpers';
import { selectApp } from '../../store/selectors/app';

import './basePage.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectApp,
			(app) => ({
				...app
			})
		),
	),
	mapProps(extractQueryParams),
	lifecycle({
		componentDidMount() {

		}
	})
);

export function BasePage(props) {
	const {
		className,
		children,
		headerContent,
		hostname,
		location
	} = props;

	return (
		<div className={classnames('base-page', className)}>
			{props.children}
		</div>
	);
}

BasePage.propTypes = {
	children: propTypes.node,
	requiresSession: propTypes.bool,
};

BasePage.defaultProps = {
	requiresSession: false,
};

export default enhance(BasePage);

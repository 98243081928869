import queryString from 'querystring';
import _ from 'lodash';
import numeral from 'numeral';

export function reactifyBool(val) {
	return !!val ? 'true' : 'false';
}

export function parseQueryParams(location) {
	return camelizeKeys(queryString.parse((location.search || '').replace(/^\?/, '')) || {});
}

export function camelizeKeys(obj) {
	if (!_.isArray(obj) && !_.isPlainObject(obj)) {
		return obj;
	}
	if (_.isArray(obj)) {
		return _.map(obj, (o) => camelizeKeys(o));
	}
	return _.transform(obj, (converted, val, key) => {
		if (_.isArray(val) || _.isPlainObject(val)) {
			val = camelizeKeys(val);
		}
		_.set(converted, _.camelCase(key), val);
		return converted;
	}, {});
}

export function modifiedSnakeCase(val) {
	return (_.snakeCase(val) || '').replace(/_([0-9]+)/g, '$1');
}

export function snakeifyKeys(obj) {
	if (!_.isArray(obj) && !_.isPlainObject(obj)) {
		return obj;
	}
	if (_.isArray(obj)) {
		return _.map(obj, (o) => snakeifyKeys(o));
	}
	return _.transform(obj, (converted, val, key) => {
		if (_.isArray(val) || _.isPlainObject(val)) {
			val = snakeifyKeys(val);
		}
		_.set(converted, modifiedSnakeCase(key), val);
		return converted;
	}, {});
}

export const extractQueryParams = (props) => ({
	...props,
	queryParams: camelizeKeys(queryString.parse((props.location.search || '').replace(/^\?/, '')))
});

import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectAssetUrl } from '../../../store/selectors/app';

import './image.scss';

export const enhance = compose(
	connect(selectAssetUrl)
);

export function Image({ src, asBackground, className, assetUrlBase }) {
	className = classnames('image', className);
	if (src.indexOf('/') === 0) {
		src = `${assetUrlBase}${src}`;
	}
	if (asBackground) {
		return (
			<div className={className} style={{backgroundImage: `url(${src})`}}></div>
		);
	}
	return (
		<img src={src} className={className} />
	);
}

Image.propTypes = {
	src: propTypes.string.isRequired
};

export default enhance(Image);

import * as React from 'react';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promiseMiddleware from 'redux-promise';

import api from './api';

const middlewares = [
	promiseMiddleware,
	thunk,
	api,

	// final thing
	// logger
];

export default middlewares;

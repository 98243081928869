import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps } from 'recompose';
import { createSelector } from 'reselect';
import Headline from '../../components/lib/headline';
import Page from '../../components/page';
import TagList from '../../components/tagList';
import { selectApp } from '../../store/selectors/app';
import { selectTags } from '../../store/selectors/posts';

import './tagsPage.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		matchTag: _.get(props, 'match.params.tag')
	})),
	connect(
		createSelector(
			selectApp,
			selectTags,
			(app, tags) => ({
				...app,
				...tags
			})
		)
	),
	lifecycle({
		componentDidMount() {
			window.scrollTo(0, 0);
		}
	})
);

export function TagsPage(props) {
	const { tags, postsBySlug, matchTag } = props;
	const sortedTags = _.sortBy(_.toPairs(tags), ([tag]) => tag);
	return (
		<Page
			{...props}
			className={classnames('tags-page')}
			withContent
			pageName="tags-page"
			title="Tags"
		>
			<div className={classnames('tags-page-content', { 'has-match-tag': !!matchTag })}>
				{!!matchTag && (
					<Headline tag="h1" as="h3">
						Posts for tag "{matchTag}"
					</Headline>
				)}
				{_.map(sortedTags, ([tag, posts]) => (
					<TagList tag={tag} tagPosts={posts} postsBySlug={postsBySlug} key={tag} />
				))}
			</div>
		</Page>
	);
}

export default enhance(TagsPage);

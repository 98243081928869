import axios from 'axios';
import { cloneDeep, get, omit } from 'lodash';

export const CALL_API = 'CALL_API';

export function callApi(endpoint, options = {
	method: 'GET',
	headers: {},
	query: {},
	data: {}
}) {
	const payload = {
		method: options.method,
		url: `${endpoint}`,
		data: options.data,
		params: options.query,
		headers: options.headers,
		validateStatus: (status) => status < 400
	};
	return axios(payload)
	.then((response) => get(response, 'data') || {});
}

export default (store) => (next) => (action = {}) => {
	const callService = action[CALL_API];

	if (typeof callService === 'undefined') {
		return next(action);
	}

	const { types, method, data, query, options } = callService;
	const [requestType, successType, failureType] = types;
	const state = store.getState();

	let { endpoint } = callService;
	if (typeof endpoint === 'function') {
		endpoint = endpoint(state);
	}

	function actionWith(actionData) {
		return omit({
			...action,
			...actionData
		}, CALL_API);
	}

	// Fire Request Action
	next(actionWith({
		type: requestType,
		data,
		query,
		options: cloneDeep(options)
	}));

	const requestOptions = {
		method: method || 'GET',
		data,
		query
	};
	let host = '/api';

	if (typeof window !== 'undefined') {
		host = `${get(window, 'location.origin')}/api`;
	} else {
		requestOptions.headers = {
			...(requestOptions.headers || {}),
		};
	}

	endpoint = `${host}${endpoint}`;

	return callApi(endpoint, requestOptions)
	.then((payload) => {
		return next(actionWith({
			type: successType,
			payload,
			options: cloneDeep(options || {})
		}))
	})
	.catch((error) => next(actionWith({
		type: failureType,
		error,
		options: cloneDeep(options || {})
	})));
};

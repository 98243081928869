import classnames from 'classnames';
import React from 'react';
import Helmet from 'react-helmet';
import { compose } from 'recompose';
import { Headline } from '../../components/lib/headline';
import Paragraph from '../../components/lib/paragraph';
import Page from '../../components/page';
import { getBaseTitle } from '../../lib/tags';

import './notFound.scss';

export const enhance = compose();

export function NotFound(props) {
	return (
		<Page
			{...props}
			className={classnames('not-found-page')}
			withContent
			pageName="not-found"
		>
			<div className="not-found-content">
				<Headline tag="h1" as="h4">
					Oops!
				</Headline>
				<Paragraph>
					Looks like that page doesn't exist.
				</Paragraph>
			</div>
		</Page>
	);
}

export default enhance(NotFound);

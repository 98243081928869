import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';

export const POSTS = {
	SET_POSTS_BY_SLUG: 'POSTS/SET_POSTS_BY_SLUG',
	SET_POST_BY_SLUG: 'POSTS/SET_POST_BY_SLUG',
	GET_BLOG_DATA_REQUEST: 'POSTS/GET_BLOG_DATA_REQUEST',
	GET_BLOG_DATA_SUCCESS: 'POSTS/GET_BLOG_DATA_SUCCESS',
	GET_BLOG_DATA_FAILURE: 'POSTS/GET_BLOG_DATA_FAILURE',
};

export const setPostsBySlug = createAction(POSTS.SET_POSTS_BY_SLUG, (postsBySlug) => ({ postsBySlug }));

export const setPostForSlug = createAction(POSTS.SET_POST_BY_SLUG, (post) => ({ post }));

export function getBlogData(query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: [POSTS.GET_BLOG_DATA_REQUEST, POSTS.GET_BLOG_DATA_SUCCESS, POSTS.GET_BLOG_DATA_FAILURE],
			endpoint: `/blog-data.json`,
			method: 'get',
			options: {
				...options,
			},
			query
		}
	};
}

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import propTypes from 'prop-types';

function transform(node) {
	if (node.type === 'script') {
		return null;
	}
	if (node.type === 'style') {
		return null;
	}
}

export function SafeHTML(props) {
	return ReactHtmlParser(props.html, {
		transform
	});
}

SafeHTML.propTypes = {
	html: propTypes.string.isRequired
};

export default SafeHTML;

import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps } from 'recompose';
import { createSelector } from 'reselect';
import Page from '../../components/page';
import Post from '../../components/post';
import { selectApp } from '../../store/selectors/app';
import { selectPostForSlug } from '../../store/selectors/posts';
import { DiscussionEmbed } from 'disqus-react';

import './postPage.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		postSlug: props.match.params.postSlug
	})),
	connect(
		createSelector(
			selectApp,
			selectPostForSlug,
			(app, post) => ({
				...app,
				...post
			})
		)
	),
	lifecycle({
		componentDidMount() {
			window.scrollTo(0, 0);
		}
	})
);

export function PostPage(props) {
	const { post } = props;
	return (
		<Page
			{...props}
			className={classnames('post-page')}
			withContent
			pageName="post"
			title={_.get(post, 'attributes.title')}
		>
			<div className="post-page-content">
				<Post html={post.bodyHtml} attributes={post.attributes} />
				<div className="comments-wrapper">
					{typeof window !== 'undefined' && (
						<DiscussionEmbed
							shortname="seanmcgarycom"
							config={
								{
									url: `${window.location.origin}${window.location.pathname}`,
									identifier: post.slug,
									title: _.get(post, 'attributes.title'),
								}
							}
						/>
					)}

				</div>
			</div>
		</Page>
	);
}

export default enhance(PostPage);

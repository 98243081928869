import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom';
import { isString, omit } from 'lodash';

import './link.scss';

export function Link(props) {
	const className = classnames('link', props.className, props.size, { underline: props.underline }, props.linkStyle);
	const combinedProps = {
		...omit(props, ['linkStyle', 'underline', 'isNavLink']),
		className
	};

	if (isString(props.to) && props.to.length && props.to.indexOf('http') === 0) {
		combinedProps.href = props.to;
		return (<a {...combinedProps} />);
	}
	if (props.isNavLink) {
		return (<RRNavLink {...combinedProps} />);
	}
	return (
		<RRLink {...combinedProps} />
	);
}

Link.propTypes = {
	size: propTypes.oneOf(['tiny', 'small', 'regular', 'medium', 'large']),
	underline: propTypes.bool,
	linkStyle: propTypes.oneOf(['blue', 'dark', 'gray', 'black', 'white']),
	isNavLink: propTypes.bool
};

Link.defaultProps = {
	size: 'regular',
	underline: false,
	linkStyle: 'blue',
	isNavLink: false
};

export default Link;

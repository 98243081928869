export const selectApp = (state) => ({
	hostname: _.get(state, 'app.hostname') || null,
	environment: _.get(state, 'app.environment') || 'staging',
	isMobile: _.get(state, 'app.isMobile'),
	isTablet: _.get(state, 'app.isTablet'),
	assetUrlBase: _.get(state, 'app.assetUrlBase') || null
});

export const selectAssetUrl = (state) => ({
	assetUrlBase: _.get(state, 'app.assetUrlBase') || null
});

import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps } from 'recompose';
import { createSelector } from 'reselect';
import Page from '../../components/page';
import Post from '../../components/post';
import { selectApp } from '../../store/selectors/app';
import { selectPostsForPage } from '../../store/selectors/posts';
import Link from '../../components/lib/link';

import './postsPage.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		pageNumber: props.match.params.pageNumber ? parseInt(props.match.params.pageNumber) : 1
	})),
	connect(
		createSelector(
			selectApp,
			selectPostsForPage,
			(app, posts) => ({
				...app,
				...posts
			})
		)
	),
	lifecycle({
		componentDidMount() {
			window.scrollTo(0, 0);
		}
	})
);

export function PostsPage(props) {
	const { posts, pageCount, pageNumber } = props;
	return (
		<Page
			{...props}
			className={classnames('posts-page')}
			withContent
			pageName="posts"
			canonicalLink={`/pages/${pageNumber}`}
		>
			<div className="posts-page-content">
				{_.map(posts, (p) => (
					<Post html={p.bodyHtml} attributes={p.attributes} key={p.attributes.slug }/>
				))}
			</div>
			{!!(pageCount && pageCount > 1) && (
				<div className="pagination-container">
					{pageNumber !== 1 && (
						<Link isNavLink to={`/pages/${pageNumber - 1}`} className="page-link prev">
							Prev
						</Link>
					)}
					{_.map(_.range(0, pageCount), (i) => (
						<Link isNavLink to={`/pages/${i + 1}`} className="page-link" key={`page-${i}`}>{i + 1}</Link>
					))}
					{pageNumber !== pageCount && (
						<Link isNavLink to={`/pages/${pageNumber + 1}`} className="page-link next">
							Next
						</Link>
					)}
				</div>
			)}

		</Page>
	);
}

export default enhance(PostsPage);

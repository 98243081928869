import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './text.scss';

export function Text(props) {
	const { className } = props;
	return (
		<span {...props}
			  className={classnames('text', className, props.size || 'regular', `weight-${props.weight}`, `color-${props.color}`, `style-${props.fontStyle}`)} />
	);
}

Text.propTypes = {
	size: propTypes.oneOf(['regular', 'small', 'medium', 'large', 'x-large']),
	weight: propTypes.oneOf(['light', 'regular', 'medium', 'bold']),
	color: propTypes.oneOf(['regular', 'gray']),
	fontStyle: propTypes.oneOf(['regular', 'italic'])
};

Text.defaultProps = {
	size: 'regular',
	weight: 'regular',
	color: 'regular',
	fontStyle: 'regular'
};

export default Text;

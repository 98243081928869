import React from 'react';
import { handleActions } from 'redux-actions';
import { APP } from './actions';

export const initialState = {
	hostname: null,
	environment: null,
	googleAnalytics: {
		trackingId: null,
		debug: false
	},
	assetUrlBase: '/',
};

export default handleActions({
	[APP.SET]: (state, action) => action.payload.app
}, initialState);

import React from 'react';
import classnames from 'classnames';

import './footer.scss';

export function Footer({ className }) {
	return (
		<div className={classnames('footer', className)}>
			<div className="copyright">
				© {(new Date()).getFullYear()} SeanMcGary.com
			</div>
		</div>
	);
}

export default Footer;

import * as React from 'react';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app/reducers';
import posts from './posts/reducers';

export default combineReducers({
	app,
	posts,

	form: formReducer
});

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { mapDispatchGetBlogDataToProps, selectGetBlogData } from '../../store/selectors/posts';

import './app.scss';

export const enhance = compose(
	connect(selectGetBlogData, mapDispatchGetBlogDataToProps),
	lifecycle({
		componentDidMount() {
			const { getBlogData } = this.props;
			// getBlogData();
		}
	})
);

export function App(props) {
	return props.children;
}

export default enhance(App);

import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import Helmet from 'react-helmet';
import { getBaseTitle } from '../../lib/tags';
import { selectApp } from '../../store/selectors/app';
import BasePage from '../basePage';
import Footer from '../footer';
import Header from '../header';

import './page.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectApp,
			(app) => ({
				...app
			})
		),
	),
);

export function Page(props) {
	const {
		className,
		children,
		headerContent,
		hostname,
		location,
		canonicalLink,
		metatags = {},
	} = props;

	return (
		<BasePage {...props} className={classnames('page', className)}>
			<Helmet>
				<title>{getBaseTitle(props.title)}</title>
				{metatags.description && (
					<meta name="description" content={metatags.description}/>
				)}
				<meta property="og:title" content={getBaseTitle(props.title)} />
				<meta property="og:url" content={canonicalLink && canonicalLink.length ? canonicalLink : `${hostname}${location.pathname}`} />
				<meta name="twitter:card" content="summary_large_image"/>
				{!!(canonicalLink && canonicalLink.length) && (
					<link rel="canonical" href={canonicalLink} />
				)}
			</Helmet>
			<Header hostname={hostname} location={location}>
				{headerContent}
			</Header>
			<div className={classnames('page-content-container')}>
				<div className={classnames('page-content')}>
					{children}
				</div>
			</div>
			<Footer />
		</BasePage>
	);
}

Page.propTypes = {
	children: propTypes.node,
	headerContent: propTypes.node,
	requiresSession: propTypes.bool,
	title: propTypes.string,
	canonicalLink: propTypes.string,
	metatags: propTypes.objectOf(propTypes.shape({
		value: propTypes.string
	}))
};

Page.defaultProps = {
	requiresSession: false,
};

export default enhance(Page);

import _ from 'lodash';
import React from 'react';
import AppWrapper from '../components/appWrapper';
import { withTracker } from '../components/lib/gaTracker';
import ArchivePage from '../pages/archive';
import Error from '../pages/error';
import NotFound from '../pages/notFound';
import PostPage from '../pages/post';
import PostsPage from '../pages/posts';
import TagsPage from '../pages/tags';

import { setPostForSlug, setPostsBySlug } from '../store/posts/actions';
import { POSTS_PER_PAGE } from '../store/posts/reducers';

export const staticRoutes = [
	{
		path: '/',
		component: AppWrapper,
		actions: {},
		loadData: () => {},
		routes: [
			{
				path: '/',
				exact: true,
				component: withTracker(PostsPage),
				actions: { setPostsBySlug },
				loadData: (props) => {
					const { actions, postsBySlug } = props;
					const pageNumber = parseInt(props.match.params.pageNumber || 1);

					const lowerBound = (pageNumber - 1) * POSTS_PER_PAGE;
					const upperBound = lowerBound + POSTS_PER_PAGE;

					const orderedPosts = _.sortBy(_.values(postsBySlug), (p) => new Date(p.attributes.publishedDate).getTime() * -1);

					const sparsePosts = _.map(orderedPosts, (p, i) => {
						if (i >= lowerBound && i < upperBound) {
							return p;
						}
						return _.pick(p, ['attributes']);
					});

					return actions.setPostsBySlug(_.reduce(sparsePosts, (mapped, p) => {
						mapped[p.attributes.slug] = p;
						return mapped;
					}, {}));
				}
			}, {
				path: '/pages/:pageNumber',
				exact: true,
				component: withTracker(PostsPage),
				actions: { setPostsBySlug },
				loadData: (props) => {
					const { actions, postsBySlug } = props;
					const pageNumber = parseInt(props.match.params.pageNumber || 0);

					const lowerBound = (pageNumber - 1) * POSTS_PER_PAGE;
					const upperBound = lowerBound + POSTS_PER_PAGE;

					const orderedPosts = _.sortBy(_.values(postsBySlug), (p) => new Date(p.attributes.publishedDate).getTime() * -1);

					const sparsePosts = _.map(orderedPosts, (p, i) => {
						if (i >= lowerBound && i < upperBound) {
							return p;
						}
						return _.pick(p, ['attributes']);
					});

					return actions.setPostsBySlug(_.reduce(sparsePosts, (mapped, p) => {
						mapped[p.attributes.slug] = p;
						return mapped;
					}, {}));
				}
			}, {
				path: '/posts/:postSlug',
				exact: true,
				component: withTracker(PostPage),
				actions: { setPostForSlug },
				loadData: (props) => {
					const { postSlug } = props.match.params;
					const { actions, postsBySlug } = props;
					return actions.setPostForSlug(_.get(postsBySlug, postSlug));
				}
			}, {
				path: '/archive',
				exact: true,
				component: withTracker(ArchivePage),
				actions: { setPostsBySlug },
				loadData: (props) => {
					const { actions, postsBySlug } = props;
					return actions.setPostsBySlug(_.transform(postsBySlug, (mapped, post) => {
						mapped[post.attributes.slug] = _.pick(post, ['attributes']);
						return mapped;
					}));
				}
			}, {
				path: '/tags',
				exact: true,
				component: withTracker(TagsPage),
				actions: { setPostsBySlug },
				loadData: (props) => {
					const { actions, postsBySlug } = props;
					return actions.setPostsBySlug(_.transform(postsBySlug, (mapped, post) => {
						mapped[post.attributes.slug] = _.pick(post, ['attributes']);
						return mapped;
					}));
				}
			}, {
				path: '/tags/:tag',
				exact: true,
				component: withTracker(TagsPage),
				actions: { setPostsBySlug },
				loadData: (props) => {
					const { actions, postsBySlug } = props;
					return actions.setPostsBySlug(_.transform(postsBySlug, (mapped, post) => {
						mapped[post.attributes.slug] = _.pick(post, ['attributes']);
						return mapped;
					}));
				}
			}, {
				path: '/error',
				exact: true,
				component: withTracker(Error),
				actions: { },
				loadData: (props) => {}
			}, {
				path: '*',
				component: withTracker(NotFound),
				actions: {},
				loadData: () => {}
			}
		]
	}
];

export default function Routes() {}

import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import './post.scss';
import Avatar from '../lib/avatar/avatar';
import Headline from '../lib/headline';
import Link from '../lib/link';
import MIcon from '../lib/materialIcon';
import Text from '../lib/text';
import moment from 'moment';
import SafeHTML from '../safeHtml';

export function Post(props) {
	return (
		<article className={classnames('post', props.className)}>
			<div className="post-headline">
				<div className="post-title">
					<Avatar src={props.attributes.author.avatarUrl} />
					<Link isNavLink to={`/posts/${props.attributes.slug}`}>
						<Headline tag={props.inline ? 'h2' : 'h1'} as="h2" weight="bold">
							{props.attributes.title}
						</Headline>
					</Link>
				</div>
				<div className="post-meta">
					<Text className="author-name" color="gray">by {props.attributes.author.name}</Text>
					<Text className="published-date" color="gray">on {moment(props.attributes.publishedDate).format('LL')}</Text>
				</div>
			</div>
			<div className="post-body">
				<SafeHTML html={props.html} />
			</div>
			<div className="post-footer">
				{!!(props.attributes.tags && props.attributes.tags.length) && (
					<div className="tags-list-wrapper">
						<MIcon icon="local_offer" />
						<div className="tags-list">
							{_.map(_.uniq(props.attributes.tags), (t, i) => (
								<React.Fragment key={`${props.attributes.slug}_${t}`}>
									<Link isNavLink to={`/tags/${t}`}>{t}</Link>
									{i !== props.attributes.tags.length - 1 ? ', ' : ''}
								</React.Fragment>
							))}
						</div>
					</div>
				)}
			</div>
		</article>
	);
}

Post.propTypes = {
	inline: propTypes.bool,
	html: propTypes.string.isRequired,
	attributes: propTypes.shape({
		author: propTypes.shape({
			name: propTypes.string.isRequired,
			avatarUrl: propTypes.string.isRequired,
		}),
		title: propTypes.string.isRequired,
		slug: propTypes.string.isRequired,
		publishedDate: propTypes.any.isRequired,
		tags: propTypes.arrayOf(propTypes.string),
	}).isRequired
}

Post.defaultProps = {
	inline: false
};

export default Post;

import { getBlogData, POSTS } from '../posts/actions';
import _ from 'lodash';
import { POSTS_PER_PAGE } from '../posts/reducers';

export const selectPostForSlug = (state, props) => ({
	post: _.get(state.posts, `postsBySlug[${props.postSlug}]`) || null
});


export const selectGetBlogData = (state, props) => ({
	gettingBlogData: _.get(state.posts, 'gettingBlogData') || {}
});

export const mapDispatchGetBlogDataToProps = {
	getBlogData
}


// posts
export const selectPostsForPage = (state, props) => {
	const orderedPosts = _.sortBy(_.values(_.get(state.posts, 'postsBySlug')), (p) => new Date(p.attributes.publishedDate).getTime() * -1);
	const start = (props.pageNumber - 1) * POSTS_PER_PAGE;
	return {
		posts: orderedPosts.slice(start, start + POSTS_PER_PAGE),
		pageCount: _.get(state.posts, 'pageCount') || 0
	};
};

// posts
export const selectArchivePosts = (state, props) => {
	const orderedPosts = _.sortBy(_.values(_.get(state.posts, 'postsBySlug')), (p) => new Date(p.attributes.publishedDate).getTime() * -1);
	return {
		posts: orderedPosts
	};
};

// posts
export const selectTags = (state, props) => {
	let tags = _.reduce(_.values(_.get(state.posts, 'postsBySlug')), (mapped, post) => {
		_.each(post.attributes.tags, (tag) => {
			if (!mapped[tag]) {
				mapped[tag] = [];
			}
			mapped[tag].push(post.attributes.slug);
		});
		return mapped;
	}, {});
	if (props.matchTag) {
		tags = _.pick(tags, [props.matchTag]);
	}
	return {
		tags,
		postsBySlug: _.get(state.posts, 'postsBySlug') || {}
	};
};

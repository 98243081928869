import qs from 'querystring';
import React from 'react';
import { compose, lifecycle } from 'recompose';
import ReactGA from 'react-ga';
import { camelizeKeys } from '../../../lib/helpers';

export function trackPage(page) {
	ReactGA.set({ page });
	ReactGA.pageview(page);
}

function buildPage(page, search) {
	const queryParams = _.transform(search && search.length ? qs.parse((search || '').replace(/^\?/, '')) : {}, (pickedParams, val, key) => {
		if (key.match(/^(q|utm_.*)$/)) {
			pickedParams[key] = val;
		}
		return pickedParams;
	}, {});
	const queryString = !_.isEmpty(queryParams) ? `?${qs.stringify(queryParams)}` : '';
	return `${page}${queryString}`;
}

export const withTracker = compose(
	lifecycle({
		componentDidMount() {
			const page = this.props.location.pathname;
			const search = this.props.location.search;

			trackPage(buildPage(page, search));
		},
		componentDidUpdate(prevProps) {
			const currentPage = prevProps.location.pathname;
			const nextPage = this.props.location.pathname;
			const nextSearch = this.props.location.search;
			if (currentPage !== nextPage) {
				trackPage(buildPage(nextPage, nextSearch));
			}
		}
	})
);

import React from 'react';
import { handleActions } from 'redux-actions';
import { POSTS } from './actions';

export const POSTS_PER_PAGE = 10;

export const initialState = {
	postsByDate: [],
	postsBySlug: {},
	gettingBlogData: {
		loading: false,
		error: null
	},
	pageCount: 0
};

export default handleActions({
	[POSTS.SET_POSTS_BY_SLUG]: (state, { payload }) => {
		return {
			...state,
			postsBySlug: payload.postsBySlug
		}
	},
	[POSTS.SET_POST_BY_SLUG]: (state, { payload }) => {
		return {
			...state,
			postsBySlug: {
				...state.postsBySlug,
				[_.get(payload, 'post.attributes.slug')]: payload.post
			}
		}
	},
	[POSTS.GET_BLOG_DATA_REQUEST]: (state, { payload }) => {
		return {
			...state,
			gettingBlogData: {
				loading: true,
				error: null
			}
		}
	},
	[POSTS.GET_BLOG_DATA_SUCCESS]: (state, { payload }) => {
		const { postsBySlug } = payload;
		return {
			...state,
			postsBySlug,
			gettingBlogData: {
				loading: false,
				error: null
			},
			pageCount: Math.ceil(_.values(postsBySlug).length / POSTS_PER_PAGE)
		}
	},
	[POSTS.GET_BLOG_DATA_FAILURE]: (state, { payload }) => {
		return {
			...state,
			gettingBlogData: {
				loading: false,
				error: _.get(error, 'data'),
			}
		}
	}
}, initialState);

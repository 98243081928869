import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Headline from '../lib/headline';
import Link from '../lib/link';

import './tagList.scss';

export function TagList(props) {
	const { postsBySlug, tag, tagPosts } = props;

	return (
		<div className={classnames('tag-list', props.className)}>
			<Headline tag="h3" as="h5" className="tag-list-tag">
				{tag}
			</Headline>
			<div className="posts">
				{_.map(tagPosts, (slug) => {
					const post = _.get(postsBySlug, slug);
					if (!post) {
						return null;
					}
					return (
						<Link to={`/posts/${slug}`} isNavLink key={`${tag}-${slug}`}>
							{post.attributes.title}
						</Link>
					);
				})}
			</div>
		</div>
	);
}

TagList.propTypes = {
	tag: propTypes.string.isRequired,
	tagPosts: propTypes.arrayOf(propTypes.string).isRequired,
	postsBySlug: propTypes.object.isRequired
};

export default TagList;

import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './paragraph.scss';

export function Paragraph(props) {
	const { className } = props;
	return (
		<p {...props} className={classnames('paragraph', className, props.size || 'regular', `color-${props.color}`, `weight-${props.weight}`)} />
	);
}

Paragraph.propTypes = {
	size: propTypes.oneOf(['regular', 'small', 'medium', 'large', 'x-large']),
	color: propTypes.oneOf(['regular', 'white', 'gray']),
	weight: propTypes.oneOf(['light', 'regular', 'medium', 'bold'])
};

Paragraph.defaultProps = {
	size: 'regular',
	color: 'regular',
	weight: 'regular'
};

export default Paragraph;

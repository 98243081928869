import hljs from 'highlight.js/lib/core';
import bash from 'highlight.js/lib/languages/bash';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import python from 'highlight.js/lib/languages/python';
import yaml from 'highlight.js/lib/languages/yaml';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';

import App from './components/app';

import { staticRoutes } from './routes';
import createStore from './store';
import history from './store/history';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('python', python);
hljs.registerLanguage('php', php);
hljs.registerLanguage('yaml', yaml);

const store = createStore(_.get(window, '__PRELOADED_STATE__'));

const { trackingId, debug } = (_.get(store.getState(), 'app.googleAnalytics') || {});
if (trackingId) {
	ReactGA.initialize(trackingId, { debug });
} else {
	console.warn('GA not initialized. Missing tracking ID');
}

function Application() {
	return (
		<Provider store={store}>
			<App>
				<Router history={history}>
					{renderRoutes(staticRoutes)}
				</Router>
			</App>
		</Provider>
	);
}

ReactDOM.hydrate(<Application />, document.getElementById('application-container'));
hljs.initHighlightingOnLoad();
